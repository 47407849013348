import React from 'react';
import { renderToString } from 'react-dom/server';
import { withTranslations } from 'react-utilities';
import PropTypes from 'prop-types';
import PriceLabel from './PriceLabel';
import itemPurchaseConstants from '../constants/itemPurchaseConstants';
import translationConfig from '../translation.config';
import { getMetaData } from '../util/itemPurchaseUtil';

const { resources } = itemPurchaseConstants;

function BalanceAfterSaleText({ translate, expectedPrice }) {
  const { userRobuxBalance } = getMetaData();
  const balanceAfterSale = userRobuxBalance - expectedPrice;
  return (
    <span
      dangerouslySetInnerHTML={{
        __html: translate(resources.balanceAfterMessage, {
          robuxBalance: renderToString(
            <PriceLabel {...{ price: balanceAfterSale, color: 'gray' }} />
          )
        })
      }}
    />
  );
}
BalanceAfterSaleText.propTypes = {
  expectedPrice: PropTypes.number.isRequired,
  translate: PropTypes.func.isRequired
};

export default withTranslations(BalanceAfterSaleText, translationConfig.purchasingResources);
