export default {
  errorTypeIds: {
    transactionFailure: 'TransactionFailureView',
    insufficientFunds: 'InsufficientFundsView',
    priceChanged: 'PriceChangedView'
  },
  errorStatusText: {
    badRequest: 'Bad Request'
  },
  events: {
    startItemPurchase: 'ItemPurchaseStart'
  },
  resources: {
    freeLabel: 'Label.Free',
    okAction: 'Action.Ok',
    insufficientFundsHeading: 'Heading.InsufficientFunds',
    insufficientFundsMessage: 'Message.InsufficientFunds',
    cancelAction: 'Action.Cancel',
    buyRobuxAction: 'Action.BuyRobux',
    buyAccessAction: 'Action.BuyAccess',
    buyItemHeading: 'Heading.BuyItem',
    rentNowAction: 'Action.RentNow',
    buyNowAction: 'Action.BuyNow',
    rentItemHeading: 'Heading.RentItem',
    getItemHeading: 'Heading.GetItem',
    getNowAction: 'Action.GetNow',
    priceChangedHeading: 'Heading.PriceChanged',
    priceChangedMessage: 'Message.PriceChanged',
    balanceAfterMessage: 'Message.BalanceAfter',
    agreeAndPayLabel: 'Label.AgreeAndPay',
    promptRentAccessMessage: 'Message.PromptRentAccess',
    promptRentMessage: 'Message.PromptRent',
    promptGetFreeAccessMessage: 'Message.PromptGetFreeAccess',
    promptGetFreeMessage: 'Message.PromptGetFree',
    promptBuyAccessMessage: 'Message.PromptBuyAccess',
    promptBuyMessage: 'Message.PromptBuy',
    configureAction: 'Action.Customize',
    notNowAction: 'Action.NotNow',
    customizeAction: 'Action.Customize',
    continueAction: 'Action.Continue',
    purchaseCompleteHeading: 'Heading.PurchaseComplete',
    successfullyBoughtAccessMessage: 'Message.SuccessfullyBoughtAccess',
    successfullyBoughtMessage: 'Message.SuccessfullyBought',
    successfullyRentedAccessMessage: 'Message.SuccessfullyRented',
    successfullyRenewedAccessMessage: 'Message.SuccessfullyRenewedAccess',
    successfullyRenewedMessage: 'Message.SuccessfullyRenewed',
    successfullyAcquiredAccessMessage: 'Message.SuccessfullyAcquiredAccess',
    successfullyAcquiredMessage: 'Message.SuccessfullyAcquired',
    errorOccuredHeading: 'Heading.ErrorOccured',
    purchasingUnavailableMessage: 'Message.PurchasingUnavailable',
    buyAction: 'Action.Buy',
    rentAction: 'Action.Rent',
    installAction: 'Action.Install',
    getAction: 'Action.Get',
    bestPriceLabel: 'Label.BestPrice',
    priceLabel: 'Label.Price',
    premiumDiscountOpportunityPromptLabel: 'Label.PremiumDiscountOpportunityPrompt',
    premiumDiscountSavingsLabel: 'Label.PremiumDiscountSavings',
    premiumExclusiveEligiblePromptLabel: 'Label.PremiumExclusiveEligiblePrompt',
    premiumExclusiveIneligiblePromptLabel: 'Label.PremiumExclusiveIneligiblePrompt',
    getPremiumAction: 'Action.GetPremium',
    itemNotCurrentlyForSaleLabel: 'Label.ItemNotCurrentlyForSale',
    itemNoLongerForSaleLabel: 'Label.ItemNoLongerForSale',
    purchasingTemporarilyUnavailableLabel: 'Label.PurchasingTemporarilyUnavailable',
    itemAvailableInventoryLabel: 'Label.ItemAvailableInventory',
    noOneCurrentlySellingLabel: 'Label.NoOneCurrentlySelling',
    inventoryAction: 'Action.Inventory',
    OffsaleCountdownHourMinuteSecondLabel: 'Label.OffsaleCountdownHourMinuteSecond',
    CountdownTimerDayHourMinute: 'Label.CountdownTimerDayHourMinute'
  },
  assetTypes: {
    // library catagory type
    Plugin: 38,
    Decal: 13,
    Model: 10,
    Video: 62,
    MeshPart: 40,
    //  no category type
    Place: 9,
    Badge: 21,
    GamePass: 34,
    Animation: 24
    // others catalog category type
  },
  assetCategory: {
    Catalog: 0,
    Library: 1
  },
  errorMessages: {
    insufficientFunds: 'InsufficientFunds',
    retryErrorMessage: 'Failed to determine purchasability status. Please try again by refreshing.',
    notForSale: 'NotForSale',
    unauthorizedMessage: 'Unauthorized'
  }
};
