import { httpService } from 'core-utilities';
import urlConstants from '../constants/urlConstants';

const {
  getItemDetailsUrl,
  getPurchaseableDetailUrl,
  getResellerDataUrl,
  getMetaDataUrl
} = urlConstants;

export default {
  getItemDetails: (itemId, itemType) => {
    const urlConfig = {
      url: getItemDetailsUrl(itemId, itemType),
      retryable: true,
      withCredentials: true
    };
    return httpService.get(urlConfig);
  },
  getItemPurchasableDetail: productId => {
    const urlConfig = {
      url: getPurchaseableDetailUrl(productId),
      retryable: true,
      withCredentials: true
    };
    return httpService.get(urlConfig);
  },
  getResellerDetail: assetId => {
    const urlConfig = {
      url: getResellerDataUrl(assetId),
      retryable: true,
      withCredentials: true
    };
    return httpService.get(urlConfig);
  },
  getEconomyMetadata: () => {
    const urlConfig = {
      url: getMetaDataUrl(),
      retryable: true,
      withCredentials: true
    };
    return httpService.get(urlConfig);
  }
};
